.storage__card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 2rem 3rem;
  margin: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.storage__header {
  text-align: center;
}

.storage__body {
  margin: 2rem 12px;
}

.storage__body ul {
  list-style-type: none;
}

.storage__header h4 {
  margin-bottom: 1rem;
}

.storage__header h5 {
  color: var(--light-blue);
  font-weight: 400;
}

.storage__footer {
  font-weight: 600;
  color: var(--light-orange);
  margin: 1rem 0;
  text-align: center;
}

.storage__footer a:link,
.storage__footer a:visited {
  color: var(--light-orange);
}

.storage__footer a:hover,
.storage__footer a:active {
  color: var(--light-blue);
}

.splide__pagination__page.is-active {
  background: var(--light-orange) !important;
}

.splide__pagination {
  bottom: -1.5em !important;
}

.storage-solutions-text {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .storage__card {
    min-width: 350px;
  }
  .splide__slide {
    transition: all 0.2s ease;
    transform: scale(0.8);
    display: flex;
    justify-content: center;
  }
  .splide__slide.is-active {
    transform: scale(1);
    z-index: 200;
  }

  .splide__slide.is-next {
    z-index: 100;
  }

  .splide__slide.is-prev,
  .splide__slide.is-next {
    transform: scale(0.9);
  }
}

@media (min-width: 1024px) {
  .splide__arrow--prev {
    left: -2.5rem !important;
  }
  .splide__arrow--next {
    right: -2.5rem !important;
  }
}

@media (min-width: 1440px) {
  .splide__arrow--prev {
    left: -3.5rem !important;
  }
  .splide__arrow--next {
    right: -3.5rem !important;
  }
}
