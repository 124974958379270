.dark-blue-box {
  height: 30px;
  background-color: white;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 25px 25px 0px 0px;
  border-top: 0px solid white;
  z-index: 10;
}

.white-box {
  height: 30px;
  background-color: var(--dark-blue);
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.project-level-authorization-title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 1rem;
}

.project-level-authorization,
.SRAM-container {
  margin-top: 3rem;
}

.project-level-authorization-text {
  width: 80%;
}

.project-level-authorization-roles {
  display: grid;
  width: 100%;
  column-gap: 12px;
  grid-template-columns: 33% 33% 33%;
  margin-top: 3rem;
  justify-content: center;
}

.project-level-authorization-role-icon {
  text-align: center;
}

.project-level-authorization-role-icon img {
  width: 100px;
  height: 100px;
}

.project-level-authorization-role-title {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
}

.SRAM-container {
  margin-top: 10rem;
  display: flex;
  column-gap: 40px;
  text-align: right;
}

.surf-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.surf-logo img {
  height: 200px;
  margin-top: -1rem;
}

.ability {
  display: grid;
  grid-template-columns: 20px auto;
  text-align: left;
  margin-left: 30%;
}

.ability svg {
  vertical-align: middle;
  margin-right: 10px;
}

.icon-green {
  fill: var(--green);
}

.icon-red {
  fill: var(--dark-orange);
}

@media (max-width: 1024px) {
  .access-management-header {
    width: 100%;
    text-align: center;
  }
  .project-level-authorization-text {
    width: 100%;
    text-align: left;
  }
  .project-level-authorization-roles {
    grid-template-columns: 100%;
  }
  .project-level-authorization-role {
    margin-bottom: 3rem;
  }
  .SRAM-container {
    margin-top: 3rem;
    flex-direction: column-reverse;
  }
  .sram-title {
    grid-column: unset;
    text-align: unset;
  }

  .surf-logo {
    grid-row: 3;
    grid-column: 1;
    margin-top: 3rem;
  }
  .surf-logo img {
    height: 100px;
  }
}
