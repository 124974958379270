@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Branding colors */
:root {
  --dark-blue: #001c3d;
  --light-blue: #009fd8;
  --dark-orange: #e54e10;
  --light-orange: #f09125;
  --red: #ae0b12;
  --green: #0f9a58;
  --yellow: #ffffc9;
  --dark-grey: #f5f6fa;
  --light-grey: #c9c9c9;
  --text-dark: #091e42;
  --gray10: #e3e3e3;
  --gray05: #eeeef0;
  --gray00: #fff;
}

a {
  color: var(--light-blue);
  text-decoration: none;
}

body {
  font-family: "Poppins", "Open Sans", sans-serif;
  color: var(--text-dark);
}

html {
  scroll-behavior: smooth;
}
main {
  max-width: 100vw;
  min-height: 100vh;
}
