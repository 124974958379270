.data-publication-columns {
  display: grid;
  width: 100%;
  column-gap: 30px;
  grid-template-columns: 33% 33% 33%;
  margin-top: 3rem;
  justify-content: start;
}

.data-publication-column {
  display: grid;
  grid-template-rows: 120px 20px 120px 50px;
}

.data-publication-column-icon img {
  width: 100px;
  height: 100px;
  grid-row: 1;
}

.data-publication-column-title {
  font-weight: 700;
  font-size: 18px;
  grid-row: 2;
}

.data-publication-column-text {
  margin-top: 15px;
  grid-row: 3;
}

.data-publication-column-more {
  margin-top: 25px;
  grid-row: 4;
}

.data-publication-column-more a:link,
.data-publication-column-more a:visited {
  color: var(--dark-blue);
  text-decoration: none;
  padding: 10px 10px;
  border: 2px solid var(--dark-orange);
  border-radius: 4px;
  background-color: #ffff;
}

.data-publication-column-more a:hover,
.data-publication-column-more a:active {
  border: 2px solid var(--light-blue);
}

@media (max-width: 1024px) {
  .data-publication-columns {
    grid-template-columns: 100%;
    justify-content: center;
    margin-top: 3rem;
  }

  .data-publication-column {
    margin-bottom: 5rem;
    text-align: center;
    grid-template-rows: unset;
  }

  .data-publication-column-text {
    max-width: 50ch;
    margin: 0 auto;
  }
}
