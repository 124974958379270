.landing {
  padding: 0 !important;
}

.header {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.header__titles {
  text-align: center;
}

.header__titles h1 {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -1px;
  margin: 0;
  text-shadow: 0 0 50px hsla(0,0%,0%, .8);
  color: var(--light-blue);
}

.header__titles h2 {
  color: var(--gray00);
  font-size: 36px;
  font-weight: 600;
  text-shadow: 0 0 50px hsla(0,0%,0%, .8);
  margin: 0.5rem 0;
  line-height: 1.2;
}

.header__logo {
  display: none;
}

.header__button_container {
  margin-top: 36px;
}

.header__button {
  padding: 16px 32px;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: var(--gray00);
  border-radius: 10px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px var(--dark-orange);
  font-size: 14px;
  text-decoration: none;
  border: 1px solid white;
}
.header__button.primary {
  background-image: linear-gradient(45deg, var(--dark-orange) 0%, var(--light-orange) 51%, var(--dark-orange)100%);
  cursor: pointer;
  border: none;
  margin-bottom: 4px;
}

.header__button:hover {
  transform: translateY(1px);
}
.header__button.secondary {
  margin-left: 4px;
}
.header__button.secondary svg {
 margin-left: 4px;
}

.custom-shape-green {
  width: 60px;
  position: absolute;
  right: 10px;
  bottom: 0;
  z-index: 10;
}

.custom-shape-green img{
  object-fit: cover;
    width: 100%;
}



@media (min-width: 768px) {
  .header__button_container {
    margin-top: 20px;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
    height: 80vh;
  }
  .header__titles {
    text-align: left;
  }
  .header__titles h2 {
    font-size: 40px;
    max-width: 590px;
  }
  .header__titles h1 {
    max-width: 590px;
    font-size: 32px;
  }
  .header__logo {
    display: block;
    max-width: 300px;
  }
  .header__logo img {
    object-fit: cover;
    width: 100%;
  }
  .header__button_container {
    display: flex;
  }
  .header__button {
    min-width: 100px;
    display: block;
  }

  .header__button.primary {
    margin-bottom: 0;
  }
  .custom-shape-green {
    width: 80px;
  }
}

@media (min-width: 1024px) {
  .header__logo {
    max-width: 350px;
  }
  .header__titles h2 {
    font-size: 48px;
  }
}

@media (min-width: 1600px) {
  .custom-shape-green {
    width: 90px;
    right: -70px;
  }
}