.datastewards__logo img {
    max-width: 200px;
}

.datastewards__logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.datastewards__wrapper,.datastewards__content {
    margin-top: 1rem;
}

.datastewards__content_small {
    margin-bottom: 3rem;
}

.datastewards__content_small h5 {
font-size: 18px;
}


@media (min-width: 768px) {
    .datastewards__wrapper {
        display: flex;
    }
    .datastewards__logo {
        width: 50%;
    }
    .datastewards__content_small {
        max-width: 600px;
    }
}
