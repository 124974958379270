.custom-shape-divider-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }
  
  .custom-shape-divider-bottom.grey {
    transform: none;
    background-color: var(--dark-grey);
    z-index: -1;
  }
  
  .custom-shape-divider-bottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
  }
  
  .custom-shape-divider-bottom.grey .shape-fill{
    fill : #FAFBFC;
  }
  
  .custom-shape-divider-bottom .shape-fill {
    fill: #ffffff;
  }