.navbar__container {
  width: 100vw;
  height: 75px;
  background-color: var(--dark-blue);
  color: white;
  position: fixed;
  top: 0;
  z-index: 15;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--dark-orange);
  transform-origin: 0%;
}

.navbar__brand {
  width: 50px;
}

.navbar__links_container,
.navbar__brand {
  display: flex;
  align-items: center;
}

.navbar__brand img {
  object-fit: cover;
  width: 100%;
}

.navbar__links {
  list-style: none;
  display: flex;
}

.navbar__links li {
  margin-left: 12px;
}
.navbar__links li a svg,
.dropdown__links li a svg {
  margin-right: 6px;
}

.navbar__links li a,
.dropdown__links li a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all ease;
}
.dropdown__links li a {
  color: var(--dark-blue);
}

.mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__links li a:hover {
  color: var(--light-orange);
}

.mobile button {
  color: white;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mobile svg {
  font-size: 2rem;
}

.dropdown {
  position: absolute;
  right: 20px;
  bottom: -240px;
  background-color: white;
  padding: 1.5rem 1rem;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 250px;
  width: calc(100vw - 40px);
  display: flex;
  align-items: center;
}

.dropdown__links {
  width: 100%;
  font-size: 18px;
 
}

.dropdown__links li {
  list-style: none;
  padding: 12px 0;
}

.arrow-up {
  position: absolute;
  color: white;
  top: -1.1rem;
  font-size: 2rem;
  right: 0;
}

.dropdown__links li:first-child {
  padding-bottom: 6px;
}

.navbar__links_container.large {
  display: none;
}

@media (min-width: 768px) {
  .navbar__links_container.large {
    display: flex;
  }
  .mobile,
  .dropdown,
  .dropdown.open {
    display: none;
  }
  #blog, #help-center {
    padding-right: 20px;
  }
  .navbar__container {
    padding: 0 3rem;
  }
}

@media (min-width: 1224px) {
  .navbar__container {
    padding: 0 10vw;
  }
}

@media (min-width: 1424px) {
  .navbar__container {
    padding: 0 15vw;
  }
}
