.custom-shape-orange {
  width: 50px;
  position: absolute;
  left: 0px;
  top: -50px;
  z-index: 10;
}

.custom-shape-blue {
  position: absolute;
  right: 0px;
  top: 800px;
  z-index: 10;
  transform: rotate(180deg);
}

.data-management-support-box {
  margin-top: 20px;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 150px auto;
  justify-content: start;
  width: 55%;
}

.data-management-support-box-title,
.data-management-support-box-title-right {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}
.data-management-support-box-image {
  grid-row: 1 / span 2;
}

.data-management-support-box-image img {
  height: 150px;
  width: 150px;
}

.data-management-support-box-right {
  margin-top: 20px;
  display: grid;
  column-gap: 20px;
  grid-template-columns: auto 150px;
  justify-content: start;
  width: 55%;
  margin-left: 45%;
}

.data-management-support-box-image-right {
  grid-row: 1 / span 2;
}

.data-management-support-box-text-right {
  grid-row: 2 / span 1;
}

@media (max-width: 1024px) {
  .data-management-support-box-image img {
    height: 100px;
    width: 100px;
  }

  .data-management-support-box-right {
    margin-top: 50px;
    display: grid;
    column-gap: 20px;
    grid-template-columns: auto 100px;
    justify-content: start;
    width: 100%;
    margin-left: 0px;
  }

  .data-management-support-box {
    margin-top: 3rem;
    display: grid;
    column-gap: 20px;
    grid-template-columns: 100px auto;
    justify-content: start;
    width: 100%;
  }

  .custom-shape-blue {
    display: none;
  }

  .data-management-support-header {
    width: 100%;
  }
  .data-management-support-box-title,
  .data-management-support-box-title-right {
    font-size: unset;
  }
}
