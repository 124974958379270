/*get started section */

.get-started .content {
  margin-top: 10px;
  width: 50%;
  margin-bottom: 30px;
}
.get-started a {
  margin-left: 5px;
}

.get-started .boxes-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 3rem;
}

.get-started .boxes {
  width: 30%;
  max-width: 400px;
}

.get-started .box {
  padding: 20px;
  text-align: center;
}

.get-started .box.active {
  border: 2px solid var(--light-blue);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 8px;
}

.get-started .box .title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
}

.get-started-icon {
  width: 350px;
  height: 350px;
  animation: fadein 2s;
}


.wrap {
  background-color: rgba(25, 24, 35, 0.15);
  color: rgba(25, 24, 35, 0.15);
  height: 5px;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  margin-top: 3rem;
}

.bar {
  height: 5px;
  border-radius: 4px;
  background-color: var(--light-blue);
}

.get-started-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
}

@keyframes fadein {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.get-started-icon svg {
  animation: fadein 2s;
}


@media (max-width: 768px) {
  .get-started .content {
    width: 100%;
  }
  .get-started .boxes {
    width: 100%;
  }
  .get-started-icon {
    height: 200px;
    margin: auto;
    width: 200px;
    margin-bottom: 1rem;
  }
  .get-started .boxes-wrapper {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }
}


@media (min-width: 769px) and (max-width: 1440px) {
  .get-started .boxes {
    width: 50%;
  }
  .get-started-icon {
    width: 300px;
    height: 300px;
  }
}
